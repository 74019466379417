import React from 'react'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Home from 'components/landing/Home'
import useRedirectToAppIfAuthed from 'hooks/use-redirect-to-app-if-authed'

export default function HomeHealthyAging50() {
  useRedirectToAppIfAuthed()

  return (
    <Layout className="HomeHealthyAging50" data-test-id="home">
      <Head title="Age Bold – Variety of online fitness classes for older adults" titleOnly />
      <Home />
    </Layout>
  )
}
