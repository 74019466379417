import { navigate } from 'gatsby'
import React from 'react'
import paths from 'constants/paths'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'

export default function useRedirectToAppIfAuthed() {
  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (!cookie.getCookie(storage.REFRESH_TOKEN_KEY)) return

    navigate(paths.HOME_AUTHED, { replace: true })
  }, [])
}
